import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Routes from "./router/Routes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>KushStack | Modernized Cannabis Technology</title>
        <meta property="og:site_name" content="KushStack" />
        <meta
          property="og:url"
          content="https://kushstack.com"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="KushStack | Modernized Cannabis Technology"
        />
        <meta
          name="keywords"
          content="software, cannabis, point of sale, ecommerce, saas, kushstack"
        />
        <meta
          name="description"
          content="KushStack is a full suite of software for the Cannabis industry."
        />
      </Helmet>
      {/* End Seo Helmet */}

      <ScrollToTop />
      <Routes />
    </>
  );
};

export default App;
